import React from 'react'

const AppCard = ({title,subTitle,onClick,className}) => {
  return (
    <div className={`bg-white shadow-md rounded-lg p-6 w-full max-w-xs md:max-w-md lg:max-w-lg text-center ${className} `}>
    <h2 className="text-2xl font-semibold mb-4">{title}</h2>
    <p className="text-gray-600 mb-6">
      {subTitle}
    </p>
    <button className="bg-purple-700 text-white px-4 py-2 rounded-md hover:bg-purple-800 transition duration-200" onClick={onClick}>
      Get Started
    </button>
  </div>
  )
}

export default AppCard