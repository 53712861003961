import React from 'react'
import RoutesContainer from './routes'
import { BrowserRouter } from 'react-router-dom'

const App = () => {
  return (
   <BrowserRouter>
    <RoutesContainer/>
   </BrowserRouter>



  )
}

export default App