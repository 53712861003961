import React from "react";
import SideNav from "./sideNav";
import Footer from "./footer";
import Header from "./header";

const Layout = ({ children }) => {
  return (
    <>
      <div className="flex flex-col w-full ">
        <header className="w-full z-10">
          <Header />
        </header>
        <main className="flex-1 overflow-auto bg-white">
          <div className="w-full h-full ">{children}</div>
        </main>
        <footer className="w-full border-t border-gray-200 bg-white">
          <Footer />
        </footer>
      </div>
    </>
  );
};

export default Layout;
