import React from "react";
import { Link, useNavigate } from "react-router-dom";

const InficonnectHome = () => {
  const navigate = useNavigate()
  const handleStarted = () => {
     navigate("/products/inficonnect/overview")
  }
  return (
    <div>
      <div className="w-full flex justify-between items-center px-4 sm:px-8 bg-purple-600 py-4 sm:py-4 border border-b shadow">
        <div className="container flex justify-between items-center w-full">
        <div className="flex items-center space-x-4">
          <span className="text-lg sm:text-2xl font-semibold text-white">
            API Developer
          </span>
        </div>
        <nav className="hidden sm:flex space-x-4 sm:space-x-6">
          <Link to="/products">
            <a
              href="#"
              className="text-black hover:text-white font-semibold"
            >
              Products
            </a>
          </Link>
          <a href="#" className="text-black hover:text-white font-semibold">
            Blog
          </a>
          <a href="#" className="text-black hover:text-white font-semibold">
            Create a Developer Account
          </a>
        </nav>
        <button className="bg-white py-1 px-4 font-semibold text-purple-800">Sign In</button>
        </div>
      </div>
      <div className=" flex flex-col md:flex-row h-auto md:h-96 px-4 md:px-14 bg-blue-50  ">
      <div className="flex container ">
        <div className="w-full md:w-1/2 flex items-center mt-8 md:mt-10 ">
          <div className="w-full">
            <h1 className="text-4xl md:text-6xl font-bold">InfiConnect</h1>
            <p className="text-gray-600 font-light mt-4 md:mt-8 text-lg md:text-xl">
              We are a leading regional Instagram influencer marketing agency in
              Hyderabad. Our team integrates with yours to ensure proactive,
              real-time communication, while creating authentic, data-driven
              campaigns that get to the heart and soul of your brand story.
            </p>
            <button className="border bg-purple-700 hover:bg-purple-800 text-white mt-10 py-3 px-6 font-semibold" onClick={handleStarted}>Documents</button>
          </div>
        </div>

        <div className="w-full md:w-1/2 flex items-center mt-6 md:mt-10 h-auto md:h-72">
          <img
            src="https://img.freepik.com/free-vector/gradient-devops-illustration_23-2149368729.jpg"
            alt="inficonnect"
            className="w-full h-full object-contain"
          />
        </div>
      </div>
        
      </div>
    </div>
  );
};

export default InficonnectHome;
