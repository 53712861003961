import React from "react";

const StoreDesign = () => {
 

  return (
    <div className="p-6 bg-red-50 min-h-screen">
      <div className="flex flex-col lg:flex-row justify-between lg:items-start gap-6 p-6">
        
        <div className="flex-1">
          <h2 className="text-gray-600 text-sm">Projects Adobe Analytics</h2>
          <div className="flex items-center gap-4 mt-5">
            <div className="h-12 w-12 bg-violet-100 rounded-lg"></div>
            <div>
              <h1 className="text-2xl font-bold">Adobe Analytics</h1>
              <p className="text-xs text-gray-500">56000 | Digital Product</p>
            </div>
          </div>
          <p className="text-xs text-gray-800 mt-4 font-bold">
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s
          </p>
          <div className="mt-5">
            <h2 className="text-xs font-bold text-gray-400">Project Manager</h2>
            <div className="flex flex-wrap gap-6 mt-3">
              <div className="flex gap-2 items-center">
                <div className="h-10 w-10 bg-yellow-100 rounded-lg"></div>
                <div className="text-sm font-medium">Name here</div>
              </div>
              <div className="flex gap-2 items-center">
                <div className="h-10 w-10 bg-pink-100 rounded-lg"></div>
                <div className="text-sm font-medium">Name here</div>
              </div>
            </div>
          </div>
        </div>

       
        <div className="lg:w-1/3">
          <div className="shadow-lg py-4 px-4 bg-white rounded-lg">
            <div className="flex justify-between items-center">
              <div className="text-xl font-semibold">Members | 15</div>
              <button className="border border-gray-800 py-1 px-4 rounded-lg text-gray-800">
                + invite
              </button>
            </div>
            <div className="grid grid-cols-3 sm:grid-cols-5 gap-4 p-4">
             
                <div  className="h-12 w-12 bg-red-100 rounded-lg"></div>
                <div  className="h-12 w-12 bg-red-100 rounded-lg"></div>
                <div  className="h-12 w-12 bg-red-100 rounded-lg"></div>
                <div  className="h-12 w-12 bg-red-100 rounded-lg"></div>
                <div  className="h-12 w-12 bg-red-100 rounded-lg"></div>
                <div  className="h-12 w-12 bg-red-100 rounded-lg"></div>
                <div  className="h-12 w-12 bg-red-100 rounded-lg"></div>
                <div  className="h-12 w-12 bg-red-100 rounded-lg"></div>
                <div  className="h-12 w-12 bg-red-100 rounded-lg"></div>
              
            </div>
            <div className="flex justify-between">
              <div className="text-sm font-semibold">Hire Employees</div>
              <div className="text-sm font-semibold">Private</div>
            </div>
          </div>
        </div>
      </div>

      
      <div className="flex flex-wrap justify-between items-center bg-white p-4 rounded-lg shadow-md mt-6 gap-4">
        <div className="flex gap-4 items-center">
          <h2 className="text-xl font-bold">Departments</h2>
          <div className="bg-gray-100 px-2 py-1 rounded flex gap-2">
            <button className="border px-2 py-1 rounded-lg bg-white">All</button>
            <button className="px-2 py-1">USA</button>
            <button className="px-2 py-1">Europe</button>
          </div>
        </div>
        <div className="flex gap-4">
          <input
            type="date"
            className="border px-4 py-2 rounded-lg"
          />
          <button className="bg-blue-500 text-white px-4 py-2 rounded-lg">
            Filters
          </button>
        </div>
      </div>

      
      <div className="overflow-x-auto mt-6">
        <table className="w-full text-left bg-white rounded-lg shadow-md">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-4">Departments, Country</th>
              <th className="p-4">Hire Employees</th>
              <th className="p-4">Deadline</th>
              <th className="p-4">Status</th>
              <th className="p-4">Recruiter</th>
            </tr>
          </thead>
          <tbody>
           
              <tr  className="border-t">
                <td className="p-4">
                  wdefrgthyu
                </td>
                <td className="p-4">dfghj</td>
                <td className="p-4">edgh</td>
                <td className="p-4">
                  <span
                    className={`px-2 py-1 rounded `}
                  >
                   ertyh
                  </span>
                </td>
                <td className="p-4">edfgh</td>
              </tr>
        
          </tbody>
        </table>
      </div>

   
      <div className="bg-gray-100 p-6 rounded-lg shadow-md mt-6">
        <h2 className="text-lg font-bold">8,000 Employees</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-4">
          <div className="text-center">
            <h3 className="text-sm text-gray-600">Hired</h3>
            <p className="font-bold text-green-600 text-2xl">74%</p>
            <p className="text-gray-500">5,321</p>
          </div>
          <div className="text-center">
            <h3 className="text-sm text-gray-600">Processing</h3>
            <p className="font-bold text-yellow-600 text-2xl">38%</p>
            <p className="text-gray-500">2,679</p>
          </div>
          <div className="text-center">
            <h3 className="text-sm text-gray-600">On Staff</h3>
            <p className="font-bold text-blue-600 text-2xl">27%</p>
            <p className="text-gray-500">1,216</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreDesign;
