import React from "react";
import { Link, useNavigate } from "react-router-dom";
import AppCard from "../component/card";
import Layout from "../component/layout";

const App = () => {
  const navigate = useNavigate()
  const handleClick = () =>{
    navigate("/apps/inficonnect")
  }
  return (
    <Layout>
      <div className="bg-white">
      <div className="bg-gradient-to-r from-green-200 via-purple-300  to-green-200 w-full h-40 flex justify-center items-center px-4 md:px-8">
        <input
          type="text"
          placeholder="Looking for ....."
          className="w-full max-w-xs p-2 rounded-md md:max-w-md md:p-3 lg:max-w-lg outline-none shadow-md"
        />
      </div>
      <h2 className="text-center font-bold text-xl mt-16">FEATURED APPS</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 p-6 mt-10 container">
       <AppCard title="InfiConnect" subTitle=" Comprehensive CRM platform for customer-facing teams."  onClick={handleClick} className="bg-rose-200/50"/>
       <AppCard title="Zero Click" subTitle=" Comprehensive CRM platform for customer-facing teams." onClick={handleClick} className="bg-green-200/50"/>
       <AppCard title="Express Bazar" subTitle=" Comprehensive CRM platform for customer-facing teams."  onClick={handleClick} className="bg-yellow-200/50"/>
       <AppCard title="InfiConnect" subTitle=" Comprehensive CRM platform for customer-facing teams."  onClick={handleClick} className="bg-blue-200/50"/>
       <AppCard title="Zero Click" subTitle=" Comprehensive CRM platform for customer-facing teams."  onClick={handleClick} className="bg-pink-200/50"/>
       <AppCard title="Express Bazar" subTitle=" Comprehensive CRM platform for customer-facing teams." onClick={handleClick} className="bg-violet-200/50" />
       <AppCard title="InfiConnect" subTitle=" Comprehensive CRM platform for customer-facing teams." onClick={handleClick} className="bg-rose-200/50"/>
       <AppCard title="Zero Click" subTitle=" Comprehensive CRM platform for customer-facing teams."  onClick={handleClick} className="bg-lime-200/50"/>
      </div>
    </div>
    </Layout>
    
  );
};

export default App;
