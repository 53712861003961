import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Overview from '../pages/overview';
import Dashboard from '../pages/dashboard';
import GetStarted from '../pages/getStarted';
import App from '../pages/app';
import InficonnectHome from '../pages/inficonnectHome';
import ProductOverview from '../pages/productOverview';
import StoreOverview from '../pages/storeOverview';
import CustomerOverview from '../pages/customerOverview';
import StoreDesign from '../pages/storedesign';

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard/>} />
      <Route path='/products' element={<App/>}/>
      <Route path='/apps/inficonnect' element={<InficonnectHome/>}/>
      <Route path='/overview' element={<Overview/>}/>
      <Route path='/products/inficonnect/overview' element={<GetStarted/>}/>
      <Route path='/started' element={<GetStarted/>}/>
      <Route path='/product' element={<ProductOverview/>}/>
      <Route path='/store' element={<StoreOverview/>}/>
      <Route path='/customer' element={<CustomerOverview/>}/>
      <Route path='/storedesign' element={<StoreDesign/>}/>
    </Routes>
  );
};

export default PrivateRoutes;
