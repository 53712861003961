import React from "react";
import { BiCustomize } from "react-icons/bi";
import { CiSettings } from "react-icons/ci";
import { FaArrowRightLong } from "react-icons/fa6";
import { GoGitBranch } from "react-icons/go";
import { HiOutlineBuildingStorefront } from "react-icons/hi2";
import { RxDashboard } from "react-icons/rx";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import {
  FaCar,
  FaGraduationCap,
  FaBolt,
  FaStore,
  FaTools,
  FaDumbbell,
  FaBriefcaseMedical,
  FaShieldAlt,
  FaBalanceScale,
  FaCog,
  FaHeart,
  FaHome,
  FaShoppingCart,
  FaPhone,
  FaPlane,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Layout from "../component/layout";
const Dashboard = () => {
  const industries = [
    {
      icon: <FaCog size={32} />,
      title: "AEC (Architecture, Engineering, and Construction)",
    },
    { icon: <FaCar size={32} />, title: "Automotive" },
    { icon: <FaGraduationCap size={32} />, title: "Education" },
    { icon: <FaBolt size={32} />, title: "Energy & utilities" },
    { icon: <FaStore size={32} />, title: "Small businesses" },
    { icon: <FaTools size={32} />, title: "Field services" },
    {
      icon: <FaBriefcaseMedical size={32} />,
      title: "Financial services (wealth management, mortgage, advisory)",
    },
    { icon: <FaDumbbell size={32} />, title: "Fitness coaching" },
    { icon: <FaHeart size={32} />, title: "Healthcare" },
    { icon: <FaShieldAlt size={32} />, title: "Insurance" },
    { icon: <FaBalanceScale size={32} />, title: "Legal" },
    { icon: <FaCog size={32} />, title: "Manufacturing" },
    { icon: <FaHeart size={32} />, title: "Nonprofit" },
    { icon: <FaHome size={32} />, title: "Real estate" },
    { icon: <FaShoppingCart size={32} />, title: "Retail & e-commerce" },
    { icon: <FaPhone size={32} />, title: "Telecom" },
    { icon: <FaPlane size={32} />, title: "Travel & tourism" },
  ];
  return (
    <>
      <Layout>
        <div className="min-h-screen   text-white flex flex-col items-center overflow-x-hidden w-full">
          <div className="w-full bg-gradient-to-r from-green-100 via-purple-200  to-green-100">
            <div className=" container  flex flex-col justify-center items-center mt-12 sm:mt-28 px-4 text-center mb-32 w-full pb-10">
              <div className="mt-8">
                <h1 className="text-xl sm:text-3xl md:text-5xl font-bold text-black">
                  &lt;Build an industry solution/&gt;
                </h1>
                <p className="text-sm sm:text-md md:text-lg text-gray-600  mt-4 sm:mt-6">
                  Transform your industry expertise into white-labeled,
                  ready-to-deploy vertical
                  <br className="hidden sm:inline" /> apps, with minimal
                  development effort.
                </p>
              </div>
              <div className="h-auto w-full max-w-4xl bg-black text-white mt-20 py-16 px-10 sm:px-20 text-center rounded-lg flex justify-center items-center flex-col">
                <h2 className="text-3xl font-bold">
                  About the Vertical Solutions Platform
                </h2>
                <p className="mt-5 leading-7 text-lg text-gray-400">
                  A low-code cloud platform that domain experts can <br />{" "}
                  leverage to build industry-specific cloud solutions.
                </p>
                <button className="bg-white text-black mt-10 p-4 rounded font-bold hover:bg-purple-500 hover:text-white">
                  Try the Vertical Solution Platform
                </button>
              </div>
            </div>
          </div>

          <div className=" h-auto px-4 sm:px-8 py-20 text-center bg-white w-full ">
            <h2 className="container font-bold text-xl sm:text-3xl md:text-4xl text-black leading-tight">
              Why build on the Vertical <br className="hidden sm:inline" />{" "}
              Solutions platform?
            </h2>

            <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-12 sm:mt-24 gap-5">
              <div className="h-auto w-full border border-purple-700 text-black rounded-md px-6 sm:px-10 py-10">
                <div className="text-4xl text-purple-700">
                  <CiSettings />
                </div>
                <h2 className="text-black text-start text-xl sm:text-2xl font-semibold mt-4">
                  Build your solution <br className="hidden sm:inline" /> easily
                  and for free.
                </h2>
                <p className="text-black text-start mt-5 text-sm sm:text-md  leading-8 sm:leading-9">
                  Leverage Zoho CRM's framework to build your solution. Your
                  application is run on the same reliable infrastructure that
                  powers Zoho. Processes such as sign-ups, sign-ins, upgrades,
                  billing, and security are handled by Zoho.
                </p>
              </div>

              <div className="h-auto w-full border border-purple-700 text-black rounded-md px-6 sm:px-10 py-8">
                <div className="text-4xl text-purple-700">
                  <CiSettings />
                </div>
                <h2 className="text-black text-start text-xl sm:text-2xl font-semibold mt-4">
                  Own your solution completely.
                </h2>
                <p className="text-black text-start mt-5 text-sm sm:text-md  leading-8 sm:leading-9">
                  You can white-label your solution, set your own pricing
                  policy, and map your own custom domain to allow customers to
                  sign up from your website.
                </p>
              </div>

              <div className="h-auto w-full border border-purple-700 text-black rounded-md px-6 sm:px-10 py-8">
                <div className="text-4xl text-purple-700">
                  <HiOutlineBuildingStorefront />
                </div>
                <h2 className="text-black text-start text-xl sm:text-2xl font-semibold mt-4">
                  Expand your global footprint
                </h2>
                <p className="text-black text-start mt-5 text-sm sm:text-md  leading-8 sm:leading-9">
                  Publish your industry solution on Zoho's Marketplace for free.
                </p>
              </div>
            </div>
          </div>

          <div className=" h-auto px-4 sm:px-8 py-20 text-center  bg-orange-50 w-full">
            <h2 className="container font-bold text-xl sm:text-3xl md:text-4xl text-black leading-snug">
              Leverage Zoho CRM's features and customize <br /> them to build an
              industry solution.
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-12 sm:mt-24 gap-5 px-4 sm:px-20">
              <div className="group flex h-auto justify-center items-center border border-purple-700 hover:bg-purple-600 hover:text-white w-full gap-4 p-10 text-2xl text-start rounded-md font-semibold ">
                <div className="text-4xl text-purple-700 group-hover:text-white">
                  <BiCustomize />
                </div>
                <div className="text-black group-hover:text-white text-xl">
                  Product <br /> Customization
                </div>
              </div>

              <div className="group flex h-auto justify-center items-center border border-purple-700 hover:bg-purple-600 hover:text-white w-full gap-4 p-10 text-2xl text-start font-semibold rounded-md">
                <div className="text-4xl text-purple-700 group-hover:text-white">
                  <GoGitBranch />
                </div>
                <div className="text-black group-hover:text-white text-xl">
                  Workflow <br /> Management
                </div>
              </div>

              <div className="group flex h-auto justify-center items-center border border-purple-700 hover:bg-purple-600 hover:text-white w-full gap-4 p-10 text-2xl text-start font-semibold rounded-md">
                <div className="text-4xl text-purple-700 group-hover:text-white">
                  <CiSettings />
                </div>
                <div className="text-black group-hover:text-white text-xl">
                  Data <br /> Management
                </div>
              </div>

              <div className="group flex h-auto justify-center items-center border border-purple-700 hover:bg-purple-600 hover:text-white w-full gap-4 p-10 text-2xl text-start font-semibold rounded-md">
                <div className="text-4xl text-purple-700 group-hover:text-white">
                  <TbBrandGoogleAnalytics />
                </div>
                <div className="text-black group-hover:text-white text-xl">Analytics</div>
              </div>

              <div className="group flex h-auto justify-center items-center border border-purple-700 hover:bg-purple-600 hover:text-white w-full gap-4 p-10 text-2xl text-start font-semibold rounded-md">
                <div className="text-4xl text-purple-700 group-hover:text-white">
                  <RxDashboard />
                </div>
                <div className="text-black group-hover:text-white text-xl">
                  Integration <br /> through widgets <br /> and SDKs
                </div>
              </div>

              <div className="group flex h-auto justify-center items-center border border-purple-700 hover:bg-purple-600 hover:text-white w-full gap-4 p-10 text-2xl text-start font-semibold rounded-md">
                <div className="text-black group-hover:text-white">
                  Explore the <br />
                  <div className="flex items-center gap-3 text-xl">
                    Vertical Solution Platform <FaArrowRightLong className="text-4xl text-purple-700 group-hover:text-white" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          

          <div className="h-auto p-10 text-center w-full bg-green-50">
            <h1 className="container font-bold text-xl sm:text-3xl md:text-4xl text-black leading-snug text-center">
              Get Started with the <br /> Vertical Solution Platform
            </h1>
            <button className="text-white py-3 px-7 mt-10 font-semibold bg-red-500">
              Learn More
            </button>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Dashboard;
