import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = ({ onToggleSideNav }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="w-full bg-white py-4 sm:py-4 border-b shadow">
      <div className="container flex justify-between items-center px-4 sm:px-8">
      
        <div className="flex items-center space-x-4">
          <span className="text-lg sm:text-2xl font-semibold text-black">
            API Developer
          </span>
        </div>

        
        <button
          className="text-black sm:hidden focus:outline-none"
          onClick={toggleMenu}
        >
          {isMenuOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          )}
        </button>

       
        <nav
          className={`${
            !isMenuOpen ? "block" : "hidden"
          } sm:flex flex-col sm:flex-row sm:space-x-6 space-y-4 sm:space-y-0 sm:static absolute top-16 left-0 w-full sm:w-auto bg-black sm:bg-transparent z-10`}
        >
          <Link
            to="/products"
            className="text-black hover:text-purple-700 px-4 sm:px-0"
          >
            Products
          </Link>
          <a
            href="#"
            className="text-black hover:text-purple-700 px-4 sm:px-0"
          >
            Blog
          </a>
          <a
            href="#"
            className="text-black hover:text-purple-700 px-4 sm:px-0"
          >
            Create a Developer Account
          </a>
        </nav>

       
        <button className=" text-white font-semibold bg-purple-700 px-4 py-1 rounded">
          Sign In
        </button>
        <button
        className="md:hidden text-2xl"
        onClick={onToggleSideNav}
        aria-label="Toggle Side Navigation"
      >
        ☰
      </button>
      </div>
    </header>
  );
};

export default Header;
