import React from "react";
import Layout from "../component/layout";

const Overview = () => {
  return (
    <Layout>
    <div className="h-full mt-20 px-4 md:px-6 lg:px-8 mb-10 ">
  <div>
    <h1 className="text-black text-2xl md:text-4xl lg:text-5xl font-semibold ">Overview</h1>
  </div>
  <div className="mt-8">
    <h2 className="text-xl md:text-2xl font-bold">About Uber Direct</h2>
    <p className="mt-4 text-base md:text-lg font-normal text-gray-800">
      Welcome to Uber Direct!
    </p>
    <p className="mt-4 text-base md:text-lg font-normal text-gray-800 leading-7 md:leading-8">
      This platform enables merchants like you to integrate your applications and services with Uber Direct’s order delivery system. By leveraging our APIs, you can now easily automate the process of dispatching an Uber courier for pickup and deliveries.
    </p>
    <p className="mt-4 text-base md:text-lg font-normal text-gray-800 leading-7 md:leading-8">
      This developer portal provides documentation and resources to assist you in creating, managing, and monitoring deliveries using the Uber Direct platform.
    </p>
  </div>
  
  <div className="mt-8">
    <h2 className="text-xl md:text-2xl font-bold">
      The Difference Between Uber Direct APIs and Uber Eats Marketplace APIs
    </h2>
    <p className="mt-4 text-base md:text-lg font-normal text-gray-800 leading-7 md:leading-8">
      The Uber Direct API empowers merchants to seamlessly integrate their applications and services with the Uber Direct order delivery system. Once your application invokes Uber’s Create Delivery endpoint, a dedicated Uber courier will be dispatched to collect the order from the store and deliver it to the customer.
    </p>
    <p className="mt-4 text-base md:text-lg font-normal text-gray-800 leading-7 md:leading-8">
      Conversely, the Uber Eats Marketplace APIs offer partners the capability to programmatically manage various aspects of your stores, menus, and orders on the Uber Eats app. If you require assistance with managing operations on the Uber Eats platform, we encourage you to explore the resources provided in the Uber Eats APIs documentation.
    </p>
  </div>

  <div className="mt-8">
    <h2 className="text-xl md:text-2xl font-bold">Integration Process</h2>
    <p className="mt-4 text-base md:text-lg font-normal text-gray-800 leading-7 md:leading-8">
      1. Refer to the Get Started - Set Up a New Uber Direct Account guidelines to set up an Uber Direct account, which will provide access to our sandbox environment.
    </p>
    <p className="mt-4 text-base md:text-lg font-normal text-gray-800 leading-7 md:leading-8">
      2. Your team can use the Uber Direct RESTful endpoints and API documentation to develop your integration on a timeline that aligns with your roadmap. We are available to provide support as needed throughout the development process.
    </p>
    <p className="mt-4 text-base md:text-lg font-normal text-gray-800 leading-7 md:leading-8">
      3. Once your development is complete and ready for production, we will require actual stores to participate in a pilot run of the integration.
    </p>
    <p className="mt-4 text-base md:text-lg font-normal text-gray-800 leading-7 md:leading-8">
      4. Upon successful completion of the pilot run, you can deploy the integration to all stores. At this point, our 24-hour support team will also be able to help with any technical questions.
    </p>
  </div>
</div>

    </Layout>
  );
};

export default Overview;
