import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-black text-gray-300 py-8">
    
      <div className="max-w-7xl mx-auto px-4">
     
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          <h2 className="text-lg md:text-xl font-bold mb-4 md:mb-0">Developer API</h2>
        </div>

        
        <div className="border-t border-gray-700 my-6"></div>

       
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center text-sm space-y-4 md:space-y-0">
          <span className="block">&copy; {new Date().getFullYear()} Your Developer API</span>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
            <a href="/privacy" className="hover:underline">
              Privacy
            </a>
            <a href="/accessibility" className="hover:underline">
              Accessibility
            </a>
            <a href="/terms" className="hover:underline">
              Terms
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
