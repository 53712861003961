import React from "react";
import Layout from "../component/layout";
import SideNav from "../component/sideNav";
import LayoutDocs from "../component/layoutdocument";

const GetStarted = () => {
  return (
    <LayoutDocs>
         <div className="flex w-full">
      
      <div className=" h-full mt-20 ps-6">
        <div className="w-full container">
          <div className="">
            <h1 className="text-black text-2xl md:text-4xl lg:text-5xl font-semibold ">
              Get Started
            </h1>
          </div>
          <div className="mt-10">
            <p className="mt-6 text-lg font-normal text-gray-800 leading-8">
              This guide will walk you through the step-by-step process of
              creating an Developer API Account. Upon creating your account, you
              will gain access to developer credentials to interact with
              Developers APIs.
            </p>
            <p className="mt-4 text-lg font-normal text-gray-800 leading-8">
              If you encounter any issues while going through these steps,
              please reach out to your Point of Contact or Account Manager.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-2xl font-bold ">Create Account</h2>

            <p className="mt-6 text-lg font-normal text-gray-800 leading-8">
              Start by visiting: https://direct.uber.com
              <ol className=" mt-2">
                <li>
                  <p className="ms-5">1. Product API</p>

                  <pre className="bg-gray-900 text-gray-100 p-4 text-sm md:text-lg rounded-lg overflow-x-auto mt-10  w-full">
                    {`curl --location '
https://catalog-pro-engine.onrender.com/app/2024-10/product/get'
\
--header 'x-app-key: 902ac632dc03177cf85b900587437b16' \
--header 'x-app-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2ZW5kb3JJZCI6IjEwMDAxIiwidmVuZG9yT2JqSWQiOiI2NzBlNGVlMmYzMWNhODg1OGU5NTcxYmIiLCJwZXJtaXNzaW9ucyI6eyJwcm9kdWN0cyI6eyJyZWFkIjpmYWxzZSwid3JpdGUiOmZhbHNlfSwic3RvcmVzIjp7InJlYWQiOnRydWUsIndyaXRlIjp0cnVlfX0sImlhdCI6MTcyOTIyNjIwMn0.mz-iN9ldIL4aEVeRTewzFkhwF78ZXoXUswmITGP2Y7A'`}
                  </pre>
                </li>
                <li className="mt-10">
                  <p className="ms-5">2. Create Product API</p>

                  <pre className="bg-gray-900 text-gray-100 p-4 text-sm md:text-lg rounded-lg overflow-x-auto mt-10  w-full">
                    {`curl --location '
https://catalog-pro-engine.onrender.com/app/2024-10/product/create'
\
--header 'x-app-key: d22819ea77d62e63c563d458047bcc86' \
--header 'x-app-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2ZW5kb3JJZCI6IjEwMDAxIiwidmVuZG9yT2JqSWQiOiI2NzBlNGVlMmYzMWNhODg1OGU5NTcxYmIiLCJwZXJtaXNzaW9ucyI6eyJwcm9kdWN0cyI6eyJyZWFkIjp0cnVlLCJ3cml0ZSI6dHJ1ZX0sInN0b3JlcyI6eyJyZWFkIjp0cnVlLCJ3cml0ZSI6ZmFsc2V9LCJjdXN0b21lcnMiOnsicmVhZCI6ZmFsc2UsIndyaXRlIjp0cnVlfX0sImlhdCI6MTcyOTI1NTk5OX0.SgXMafzdqHdwVgz1UaFMqsqomWLcwInlGlZ-a8iy0R8' \
--header 'Content-Type: application/json' \
--data '{
"barcode":"12345678",
"title":"Sample Product 5",
"body_html":"<p>This is a sample product</p>",
"product_type":"Electronics",
"handle":"sample-product",
"status":"active",
"tags":["electronics"],
"variants":[],
"images":[
"
https://cdn.shopify.com/s/files/1/0569/0284/4581/files/printed-casual-…"
]
}'`}
                  </pre>
                  <div>
                    <p className="font-bold mt-5">
                      Note: If you receive the prompt “We’re unable to set up
                      your account.” it indicates that the email you are using
                      is already associated with an existing Uber Direct
                      organization.
                    </p>
                  </div>
                </li>
                <li className="mt-10">
                  <p className="ms-5">3. Get Product by Id</p>

                  <pre className="bg-gray-900 text-gray-100 p-4 text-sm md:text-lg rounded-lg overflow-x-auto mt-10  w-full">
                    {`curl --location '
https://catalog-pro-engine.onrender.com/app/2024-10/product/get/10000012'
\
--header 'x-app-key: d22819ea77d62e63c563d458047bcc86' \
--header 'x-app-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2ZW5kb3JJZCI6IjEwMDAxIiwidmVuZG9yT2JqSWQiOiI2NzBlNGVlMmYzMWNhODg1OGU5NTcxYmIiLCJwZXJtaXNzaW9ucyI6eyJwcm9kdWN0cyI6eyJyZWFkIjp0cnVlLCJ3cml0ZSI6dHJ1ZX0sInN0b3JlcyI6eyJyZWFkIjp0cnVlLCJ3cml0ZSI6ZmFsc2V9LCJjdXN0b21lcnMiOnsicmVhZCI6ZmFsc2UsIndyaXRlIjp0cnVlfX0sImlhdCI6MTcyOTI1NTk5OX0.SgXMafzdqHdwVgz1UaFMqsqomWLcwInlGlZ-a8iy0R8'`}
                  </pre>
                </li>
                <li className="mt-10">
                  <p className="ms-5">4. Customer Create API</p>

                  <pre className="bg-gray-900 text-gray-100 p-4 text-sm md:text-lg rounded-lg overflow-x-auto mt-10  w-full">
                    {`curl --location '
https://catalog-pro-engine.onrender.com/app/2024-10/customer/create'
\
--header 'Content-Type: application/json' \
--data-raw '{
    "email":"ajay12.bind@ens.enterprises",
        "name":"ajay",
        "phone":"7798113537",
        "vendorId":"123345",
        "description":"erwogjbv n",
        "orders":12
}'`}
                  </pre>
                </li>
              </ol>
            </p>
          </div>
          <div className="mt-14">
            <h2 className="text-2xl font-bold ">Add Billing Information</h2>
            <p className="mt-6 text-lg  leading-8">
              If you bypass step 4 during account creation, you can add billing
              information by following the steps in this section.
            </p>
            <ol className="mt-6">
              <li className="ms-5">
                <p className="text-lg ">
                  1. To add payment information, click “Billing” on the left
                  sidebar.
                </p>
                <div className="h-48 w-full sm:w-96 bg-gray-500 rounded mt-10 ms-7"></div>
              </li>
              <li className="ms-5 mt-12">
                <p className="text-lg ">
                  2. To add payment information, click “Billing” on the left
                  sidebar.
                </p>
                <div className="h-48 w-full sm:w-96 bg-gray-500 rounded mt-10 ms-7"></div>
              </li>
              <li className="ms-5 mt-12">
                <p className="text-lg ">
                  3. To add payment information, click “Billing” on the left
                  sidebar.
                </p>
                <div className="h-48 w-full sm:w-96 bg-gray-500 rounded mt-10 ms-7"></div>
              </li>
              <li className="ms-5 mt-12">
                <p className="text-lg ">
                  4. To add payment information, click “Billing” on the left
                  sidebar.
                </p>
                <div className="h-48 w-full sm:w-96 bg-gray-500 rounded mt-10 ms-7"></div>
              </li>
              <li className="ms-5 mt-12">
                <p className="text-lg ">
                  5. To add payment information, click “Billing” on the left
                  sidebar.
                </p>
                <div className="h-48 w-full sm:w-96 bg-gray-500 rounded mt-10 ms-7"></div>
                <p className="font-bold mt-5 text-lg leading-8">
                  Note: You have to provide billing information before you grant
                  the access for production credentials.
                </p>
              </li>
            </ol>
          </div>
          <div className="mt-14">
            <h2 className="font-semibold text-3xl">API Credentials</h2>
            <p className="mt-4 leading-8 text-lg">
              After you have created your account, you can access your API
              credentials by navigating to the Developer tab in the Management
              section of your dashboard. You will need to obtain your “Customer
              ID”, “Client ID”, and “Client secret” to use the API.
            </p>
            <p className="mt-4 leading-8 text-lg">
              For the difference between “Client ID”, “Customer ID” and “Client
              Secret” please refer to our FAQ.
            </p>
            <div className="h-48 w-full sm:w-96 bg-gray-500 rounded mt-10 ms-7"></div>
            <p className="mt-6 leading-8 text-lg">
              Please note the top blue area, which indicates that you are in
              Test mode. These credentials are intended for your test sandbox
              environment, and they won’t generate real-world deliveries when
              you use the APIs. Once you provide the billing information and
              your account is approved for Production deliveries, you can click
              the button to switch to the production environment, as shown in
              the image below.
            </p>
            <div className="h-48 w-full sm:w-96 bg-gray-500 rounded mt-10 ms-7"></div>
          </div>
          <div className="mt-16">
            <h2 className="font-semibold text-3xl">
              High-level Integration Flow
            </h2>
            <p className="mt-4 leading-8 text-lg">
              This guide is intended as a reference for the high-level sample
              flow. Please feel free to create bespoke workflows that align with
              the available Uber Direct APIs.
            </p>

            <div className="h-48 w-full sm:w-96 bg-gray-500 rounded mt-10 ms-7"></div>
          </div>
          <div className="mt-16">
            <h2 className="font-semibold text-3xl">Testing with Postman</h2>
            <p className="mt-4 leading-8 text-lg">
              Postman is an API development environment that simplifies running
              and testing API requests. With Postman, you won’t have to copy and
              paste data from one endpoint to another.
            </p>

            <button className="bg-orange-500 text-white border-none px-2 py-1 rounded mt-10 ">
              Run in Postman
            </button>

            <p className="text-lg mt-10">To set up Postman:</p>
            <ol className="ms-4 text-lg">
              <li className="mt-5">1. Download and install Postman.</li>
              <li className="mt-5">2. Uber’s Direct APIs are available at </li>
              <li className="mt-5">
                3. Select the Uber Direct APIs environment, and configure the
                following environment variables:
                <ul className="ms-4">
                  <li className="mt-5">
                    1. uber_api_client_id and uber_api_client_secret from your
                    test application
                  </li>
                  <li className="mt-5">
                    2. customer_id from the Developer tab of the Dashboard
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <div className="mt-16">
            <h2 className="font-semibold text-3xl">
              Get an OAuth Token Using curl
            </h2>
            <p className="mt-4 leading-8 text-lg">
              By now, you should have completed the Direct Account creation
              process through the Direct Dashboard and gained access to your
              developer application details, including your Client ID, Client
              Secret, and Customer ID.
            </p>
            <p className="mt-4 leading-8 text-lg">
              Here is an example of how to obtain an OAuth access token using
              the curl command:
            </p>
            <div className="h-48 w-full bg-gray-500 rounded mt-10 ms-8">
              <p></p>
            </div>
            <p className="mt-10 leading-8 text-lg">
              Replace and with your actual client ID and client secret. This
              command sends a POST request to the Uber OAuth server, requesting
              an access token using the client credentials grant type. The
              response will contain an access token that you can use to
              authenticate your API requests:
            </p>
            <div className="h-48 w-full bg-gray-500 rounded mt-10 ms-8">
              <p></p>
            </div>
            <p className="mt-10 leading-8 text-lg">
              For more examples, see the Authentication guide.
            </p>
          </div>
          <div className="mt-16">
            <h2 className="font-semibold text-3xl">
              Create a Quote Using the Uber Direct API Call
            </h2>
            <p className="mt-4 leading-8 text-lg">
              The primary objective of the create quote API is to assess the
              deliverability, validity, and cost associated with delivery
              between two addresses. We highly recommend conducting this step as
              a prerequisite before proceeding with an actual delivery.
            </p>

            <div className="h-48 w-full bg-gray-500 rounded mt-10 ms-8"></div>
            <p className="mt-4 leading-8 text-lg">
              Please replace with the actual access token obtained in the
              previous step. Also, make sure to replace the sample pickup and
              dropoff addresses with actual values.
            </p>
            <p className="mt-4 leading-8 text-lg">
              Please be aware that in certain regions, it is important to
              include latitude and longitude coordinates during the Create Quote
              process. We kindly request that you consult your designated Uber
              technical point of contact to verify this specific requirement for
              your region.
            </p>
            <p className="mt-4 leading-8 text-lg">
              Upon successful execution of the request, the API will return a
              time estimate and fee quote for the delivery. You can use this
              information to display the quote to the user and obtain their
              confirmation to proceed with the delivery.
            </p>
            <p className="mt-4 leading-8 text-lg">
              As an example, the response below provides details on the delivery
              fee of $5.58, an estimated pickup time of 18 minutes, and the
              total end-to-end dropoff duration of 44 minutes.
            </p>
            <div className="h-48 w-full bg-gray-500 rounded mt-10 ms-8"></div>
            <p className="mt-6 leading-8 text-lg">
              Please note that the “id” above must be saved, as it will be used
              in the next step.
            </p>
          </div>
          <div className="mt-16">
            <h2 className="font-semibold text-3xl">
              Create Test Deliveries Using Uber Direct API Call
            </h2>
            <p className="mt-4 leading-8 text-lg">
              To create a delivery using the Uber Direct API via curl, it is
              essential to include the quote_id obtained from the previous step:
            </p>

            <div className="h-96 w-full bg-gray-500 rounded mt-10 ms-8"></div>
            <p className="mt-10 leading-8 text-lg">
              You will get a response like below:
            </p>
            <div className="h-96 w-full bg-gray-500 rounded mt-10 ms-8"></div>
            <p className="mt-10 leading-8 text-lg">
              With this, you have successfully created a test delivery.
            </p>
            <p className="mt-6 leading-8 text-lg">
              Descriptions for all of the fields in the request and response can
              be found in the Create Delivery documentation.
            </p>
          </div>
          <div className="mt-16 pb-32">
            <h2 className="font-semibold text-3xl">
              Track Delivery Using tracking_url
            </h2>
            <p className="mt-4 leading-8 text-lg">
              Once you send the Create Delivery request, you can easily track
              the progress of the delivery throughout each stage of the delivery
              using the provided tracking_url in the response.
            </p>
            <p className="mt-4 leading-8 text-lg">
              We strongly encourage you to use the tracking_url value returned
              in the response of the Create Delivery request. Please do not
              manipulate or change this URL, as this URL may be subject to
              changes.
            </p>
            <p className="mt-4 leading-8 text-lg">
              Here’s an example of what you’ll see when you open the tracking
              URL:
            </p>

            <div className="h-96 w-64 bg-gray-500 rounded mt-10 ms-8"></div>
          </div>
        </div>
        <div className="w-1/3"></div>
      </div>
    </div>
    </LayoutDocs>
   
     
    
  );
};

export default GetStarted;
