import React, { useState } from 'react';
import Header from './header';
import SideNav from './sideNav';


const Layout = ({ children }) => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  
  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };

  return (
    <div className="h-screen flex flex-col">
    
      <div className="h-16">
        <Header onToggleSideNav={toggleSideNav} />
      </div>
      <div className="flex overflow-hidden relative">
        <div
          className={`absolute md:static z-10 h-full bg-gray-100 overflow-hidden flex-shrink-0 transition-transform duration-300 ${
            isSideNavOpen ? 'translate-x-0' : '-translate-x-full'
          } md:translate-x-0 w-[16%]`}
        >
          <SideNav />
        </div>
        <div className="flex-1 h-full overflow-auto w-full md:w-[84%]">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
