import React, { useState } from "react";
import { Link } from "react-router-dom";

const SideNav = () => {
  const [isDropdownOpenProduct, setIsDropdownOpenProduct] = useState(false);
  const [isDropdownOpenStore, setIsDropdownOpenStore] = useState(false);
  const [isDropdownOpenCustomer, setIsDropdownOpenCustomer] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleDropdownProduct = () => {
    setIsDropdownOpenProduct(!isDropdownOpenProduct);
  };

  const toggleDropdownStore = () => {
    setIsDropdownOpenStore(!isDropdownOpenStore);
  };

  const toggleDropdownCustomer = () => {
    setIsDropdownOpenCustomer(!isDropdownOpenCustomer);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      

      {/* Sidebar */}
      <div
        className={`w-64  bg-white h-screen p-4 border-r border-gray-300 transition-transform transform md:translate-x-0 md:static fixed z-40 top-16`}
      >
        <ul className=" space-y-2 mt-4">
          <Link to="/overview">
            <li className="hover:bg-purple-600 p-2 rounded">
              <span className="block text-purple-800 hover:text-white font-medium ">
                Overview
              </span>
            </li>
          </Link>
          <Link to="/started">
            <li className="hover:bg-gray-200 p-2 rounded">
              <span className="block text-purple-800 hover:text-white font-medium">
                Get Started
              </span>
            </li>
          </Link>

          {/* Product Dropdown */}
          <div>
            <button
              className="w-full text-left flex items-center justify-between p-2 text-purple-800 font-medium bg-gray-200 rounded hover:bg-gray-300"
              onClick={toggleDropdownProduct}
            >
              Products
              <svg
                className={`w-5 h-5 transform transition-transform text-black ${
                  isDropdownOpenProduct ? "rotate-180" : "rotate-0"
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {isDropdownOpenProduct && (
              <ul className="mt-2 ml-4 space-y-1">
                <Link to="/Product">
                  <li className="hover:bg-gray-200 p-1 ps-2 rounded">
                    <span className="block text-gray-600 hover:text-black font-medium">
                      Product Overview
                    </span>
                  </li>
                </Link>
              </ul>
            )}
          </div>

          {/* Store Dropdown */}
          <div>
            <button
              className="w-full text-left flex items-center justify-between p-2 text-gray-700 font-medium bg-gray-200 rounded hover:bg-gray-300"
              onClick={toggleDropdownStore}
            >
              Store
              <svg
                className={`w-5 h-5 transform transition-transform text-black ${
                  isDropdownOpenStore ? "rotate-180" : "rotate-0"
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {isDropdownOpenStore && (
              <ul className="mt-2 ml-4 space-y-1">
              <Link to='/store'>
                <li className="hover:bg-gray-200 p-1 ps-2 rounded">
                  <span className="block text-gray-600 hover:text-black font-medium">
                    Store Overview
                  </span>
                </li>
              </Link>
              </ul>
            )}
          </div>

          {/* Customer Dropdown */}
          <div>
            <button
              className="w-full text-left flex items-center justify-between p-2 text-gray-700 font-medium bg-gray-200 rounded hover:bg-gray-300"
              onClick={toggleDropdownCustomer}
            >
              Customer
              <svg
                className={`w-5 h-5 transform transition-transform text-black ${
                  isDropdownOpenCustomer ? "rotate-180" : "rotate-0"
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {isDropdownOpenCustomer && (
              <ul className="mt-2 ml-4 space-y-1">
               <Link to='/customer'>
               <li className="hover:bg-gray-200 p-1 ps-2 rounded">
                  <span className="block text-gray-600 hover:text-black font-medium">
                    Customer Overview
                  </span>
                </li>
               </Link>
                
              </ul>
            )}
          </div>

          <Link to="/store">
            <li className="hover:bg-gray-200 p-2 rounded mt-3">
              <span className="block text-gray-700 font-medium hover:text-black">
                Changelog
              </span>
            </li>
          </Link>
          <Link to="/product">
            <li className="hover:bg-gray-200 p-2 rounded">
              <span className="block text-gray-700 font-medium hover:text-black">
                Support
              </span>
            </li>
          </Link>
          <Link to="/customer">
            <li className="hover:bg-gray-200 p-2 rounded">
              <span className="block text-gray-700 font-medium hover:text-black">
                FAQ
              </span>
            </li>
          </Link>
        </ul>
      </div>

      {/* Background Overlay for Mobile Menu */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30"
          onClick={toggleMenu}
        />
      )}
    </>
  );
};

export default SideNav;
