import React from 'react'
import Layout from '../component/layout'

const CustomerOverview = () => {
  return (
    <Layout>
    <div className="h-full mt-20 px-4 sm:px-6 lg:px-8">
      <div className="w-full">
        <div>
          <h1 className="text-black text-3xl sm:text-4xl lg:text-5xl font-semibold">
            Customer Overview
          </h1>
        </div>
        <div className="mt-8 sm:mt-10">
          <p className="mt-4 text-base sm:text-lg font-normal text-gray-800 leading-7 sm:leading-8">
            Uber Direct facilitates real-time delivery updates by notifying
            your application through Webhooks. This guide outlines the
            step-by-step process for setting up, receiving, authenticating,
            and processing these notifications.
          </p>
        </div>

        <div className="mt-6 sm:mt-8">
          <h2 className="text-xl sm:text-2xl font-bold">
            Set Up the Product Endpoint
          </h2>
          <p className="mt-4 text-base sm:text-lg text-gray-800 leading-7 sm:leading-8">
            Webhook notifications are sent as POST requests from Uber Direct
            to an Internet endpoint that you specify. When your endpoints are
            up and listening, you can add the endpoint URLs via the Uber
            Direct Dashboard (direct.uber.com).
          </p>
          <p className="mt-4 text-base sm:text-lg font-normal text-gray-800 leading-7 sm:leading-8">
            Please provide one or more URLs to Uber if you would like to
            receive Webhooks.
          </p>
          <ol className="mt-4 space-y-6">
            <li>
              <p className="ml-4 sm:ml-5">
                1. Store API
              </p>
              <pre className="bg-gray-900 text-gray-100 p-4 text-sm md:text-lg rounded-lg overflow-x-auto mt-10  w-full">
                  {`curl --location '
https://catalog-pro-engine.onrender.com/app/2024-10/store/create'
\
--header 'x-app-key: d22819ea77d62e63c563d458047bcc86' \
--header 'x-app-access-token: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2ZW5kb3JJZCI6IjEwMDAxIiwidmVuZG9yT2JqSWQiOiI2NzBlNGVlMmYzMWNhODg1OGU5NTcxYmIiLCJwZXJtaXNzaW9ucyI6eyJwcm9kdWN0cyI6eyJyZWFkIjp0cnVlLCJ3cml0ZSI6dHJ1ZX0sInN0b3JlcyI6eyJyZWFkIjp0cnVlLCJ3cml0ZSI6ZmFsc2V9LCJjdXN0b21lcnMiOnsicmVhZCI6ZmFsc2UsIndyaXRlIjp0cnVlfX0sImlhdCI6MTcyOTI1NTk5OX0.SgXMafzdqHdwVgz1UaFMqsqomWLcwInlGlZ-a8iy0R8' \
--header 'Content-Type: application/json' \
--data-raw '{
  "storeCode":"124345",
  "storeName":"wnsts",
  "storeEmail":"ajay.bind123@ens.enterprises",
  "storePhone":"7798113536",
  "storeAddress":"sector 63",
  "managerEmail":"shubham.sharma@ens.enterprises"
}'`}
                </pre>
            </li>
            <li className="mt-8">
              <p className="ml-4 sm:ml-5">2. Customer Create</p>
              <p className="ml-8 sm:ml-10 mt-2">
                During this step, you have the opportunity to select the
                appropriate type of Webhook for each URL. For detailed
                explanations regarding the event.delivery_status and
                event.courier_update, please refer to the following resources:
              </p>
              <pre className="bg-gray-900 text-gray-100 p-4 text-sm md:text-lg rounded-lg overflow-x-auto mt-10  w-full">
                  {`curl --location '
https://catalog-pro-engine.onrender.com/app/2024-10/customer/create'
\
--header 'Content-Type: application/json' \
--data-raw '{
    "email":"ajay12.bind@ens.enterprises",
        "name":"ajay",
        "phone":"7798113537",
        "vendorId":"123345",
        "description":"erwogjbv n",
        "orders":12
}'`}
                </pre>
              <ul className="ml-10 sm:ml-12 mt-4 space-y-2">
                <li>event.delivery_status explanation</li>
                <li>event.courier_update explanation</li>
                <li>event.refund explanation</li>
              </ul>
            </li>
            <li className="mt-8">
              <p className="ml-4 sm:ml-5">
                3. Store Update
                <pre className="bg-gray-900 text-gray-100 p-4 text-sm md:text-lg rounded-lg overflow-x-auto mt-10  w-full">
                  {`curl --location --request PUT '
https://catalog-pro-engine.onrender.com/app/2024-10/store/6728a32f3b8f06a92acd0b8b'
\
--header 'Content-Type: application/json' \
--data-raw '{
  "storeName":"wnsts",
  "storeEmail":"ajay.@ens.enterprises",
  "storePhone":"7798113536",
  "storeAddress":"sector 63",
  "managerEmail":"shubham.123@ens.enterprises"
}'`}
                </pre>
              </p>
              <p className="font-bold ml-8 sm:ml-10 mt-2">
                IMPORTANT: For security concerns, you’ll need to configure
                your own web application to accept HTTPS requests from us.
              </p>
            </li>
            <li className="mt-8">
              <p className="ml-4 sm:ml-5">
                4. Choose which Webhook(s) should be sent to this URL. You can
                use one URL for all Webhook types or a separate URL for each.
              </p>
            </li>
            <li className="mt-8">
              <p className="ml-4 sm:ml-5">5. Choose which Webhook(s) should be sent to this URL. You can
              use one URL for all Webhook types or a separate URL for each. </p>
             
            </li>
          </ol>
        </div>

        <div className="mt-10 sm:mt-14">
          <h2 className="text-xl sm:text-2xl font-bold">
            Authenticating Webhooks
          </h2>
          <p className="mt-4 text-base sm:text-lg leading-7 sm:leading-8">
            Upon creating a Webhook, you will be provided with a dedicated
            Webhook Signing Key. This confidential key serves as a shared
            secret between your application and Uber, ensuring the
            authenticity and integrity of incoming messages to your Webhook
            endpoint.
          </p>
          <p className="mt-4 text-base sm:text-lg leading-7 sm:leading-8">
            To obtain the signing key for a specific Webhook, navigate to the
            respective Webhook entry and click on the triple dots symbol. From
            the dropdown menu, select the “Edit” option.
          </p>
          <div className="h-32 sm:h-48 w-full bg-gray-500 rounded mt-4 sm:mt-6 ml-4 sm:ml-5"></div>
          <p className="mt-4 text-base sm:text-lg leading-7 sm:leading-8">
            When a Webhook POST message is sent to your endpoint, it includes
            the header x-uber-signature, which is a SHA-256 hash of the
            message payload generated using the signing key. You can use the
            following steps to verify the Webhook message:
          </p>
          <ol className="mt-4 space-y-6">
            <li className="ml-4 sm:ml-5">
              <p className="text-base sm:text-lg">
                1. Compute the SHA-256 HASH of the message utilizing your
                designated signing key. It is recommended to employ a standard
                HMAC function provided by your programming language.
              </p>
              <div className="h-32 sm:h-48 w-full bg-gray-500 rounded mt-4 sm:mt-6 ml-4 sm:ml-5"></div>
            </li>
            <li className="ml-4 sm:ml-5 mt-8">
              <p className="text-base sm:text-lg">
                2. Validate the checksum by comparing it to the hash value
                present in the x-uber-signature header. If the checksum
                matches the hash value, proceed with processing the message as
                intended. Otherwise, ignore it.
              </p>
              <div className="h-32 sm:h-48 w-full bg-gray-500 rounded mt-4 sm:mt-6 ml-4 sm:ml-5"></div>
            </li>
          </ol>
          <p className="mt-4 text-base sm:text-lg leading-7 sm:leading-8">
            It is crucial to ensure the integrity and authenticity of the
            message by confirming the checksum validity before proceeding with
            any processing.
          </p>
          <p className="mt-4 text-base sm:text-lg leading-7 sm:leading-8">
            Here is an example of a Python script that computes what the
            x-uber-signature should be, given a signing key and a message
            payload:
          </p>
          <div className="h-32 sm:h-48 w-full bg-gray-500 rounded mt-4 sm:mt-6 ml-4 sm:ml-5"></div>
        </div>

        <div className="mt-12 sm:mt-14">
          <h2 className="font-semibold text-lg sm:text-xl">IMPORTANT</h2>
          <ul className="ml-4 sm:ml-6 mt-4 space-y-2">
            <li className="leading-7 sm:leading-8 text-base sm:text-lg">
              1. In the Delivery Status Webhook and Courier Update Webhook,
              you can use either x-uber-signature or x-postmates-signature for
              the above verification. However, for the Refund Webhook, please
              ensure you use x-uber-signature for verification.
            </li>
            <li className="leading-7 sm:leading-8 text-base sm:text-lg">
              2. Avoid Unintended Character Conversion in JSON/String Parsing:
              When handling the webhook’s body, please be aware that it might
              contain escape sequences for Unicode characters like \uXXXX.
              Ensure that your parsing methods maintain the original raw
              format. Some parsing functions may unintentionally convert
              escape sequences into their respective characters (e.g., \u0026
              to &). Choose parsing methods or libraries that preserve the
              original format to prevent unexpected conversions. Testing with
              inputs containing escape sequences helps confirm the integrity
              of your parsing functions.
            </li>
          </ul>
        </div>

        <div className="mt-14 sm:mt-16">
          <h2 className="font-semibold text-2xl sm:text-3xl">Retry logic</h2>
          <p className="mt-4 leading-7 sm:leading-8 text-base sm:text-lg">
            If Uber receives either of 500, 502, 503, 504 status codes,
            timeout, or any network error, the webhook event will be retried.
            The first retry will be sent 10 seconds after the initial event.
            The following events will be resent based on an exponential
            backoff algorithm, starting at 30 seconds after the 10s retry,
            then again after 60 seconds, then after 120 seconds, and so on
            until a response is received or until 3 total events were sent
            without a response.
          </p>
          <p className="mt-4 leading-7 sm:leading-8 text-base sm:text-lg">
            If the webhook is not received due to another status code, the
            recommendation is to use the Get Delivery endpoint to retrieve the
            data of the trip.
          </p>
        </div>
      </div>
    </div>
  </Layout>
  )
}

export default CustomerOverview